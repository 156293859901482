/* Education.css - Modern responsive styles for Touched Hearts Education page */

/* Base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.education-page {
  font-family: 'Montserrat', sans-serif;
  color: var(--dark);
  background-color: var(--off-white);
  line-height: 1.6;
  overflow-x: hidden;
}

/* Typography */
h1, h2, h3 {
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.5rem;
}

h1 {
  font-size: 3.75rem;
}

h2 {
  font-size: 2.5rem;
  color: var(--primary-dark);
  position: relative;
}

h2::after {
  content: '';
  display: block;
  width: 80px;
  height: 4px;
  background-color: var(--secondary-color);
  margin-top: 0.75rem;
  border-radius: 2px;
}

h3 {
  font-size: 1.5rem;
  color: var(--dark);
}

p {
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  color: var(--dark-gray);
}

.header-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--white);
  max-width: 800px;
  margin: 0 auto;
}

/* Page Header */
.page-header {
  background: linear-gradient(rgba(58, 143, 133, 0.85), rgba(44, 114, 105, 0.95)), 
  url('../assets/images/E5.jpg') no-repeat center center/cover;
  color: var(--white);
  padding: 10rem 2rem;
  text-align: center;
  position: relative;
}

.page-header h1 {
  margin-bottom: 1rem;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  animation: fadeInDown 1s ease-out;
}

.page-header .header-subtitle {
  animation: fadeInUp 1s ease-out 0.3s;
  animation-fill-mode: both;
}

/* Education Section */
.education-section {
  padding: 5rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.education-intro {
  margin-bottom: 4rem;
}

.education-intro p {
  max-width: 900px;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.initiatives-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 2.5rem;
  margin-top: 3rem;
}

.initiative-card {
  background-color: var(--white);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  opacity: 0;
  transform: translateY(30px);
}

.initiative-card.show {
  opacity: 1;
  transform: translateY(0);
}

.initiative-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.initiative-image {
  width: 100%;
  height: 240px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.initiative-card:hover .initiative-image {
  transform: scale(1.05);
}

.initiative-content {
  padding: 2rem;
  flex: 1;
  border-top: 4px solid var(--primary-color);
  position: relative;
}

.initiative-card:nth-child(2) .initiative-content {
  border-top-color: var(--secondary-color);
}

.initiative-card:nth-child(3) .initiative-content {
  border-top-color: var(--info);
}

.initiative-card:nth-child(4) .initiative-content {
  border-top-color: var(--success);
}

.initiative-content h3 {
  margin-bottom: 1rem;
  font-size: 1.75rem;
}

/* Impact Section */
.impact-section {
  background-color: var(--primary-dark);
  padding: 5rem 2rem;
  color: var(--white);
  text-align: center;
}

.impact-section h2 {
  color: var(--white);
  margin-bottom: 3rem;
}

.impact-section h2::after {
  background-color: var(--secondary-light);
  margin: 0.75rem auto 0;
}

.stats-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.stat-card {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2.5rem 1.5rem;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
  background-color: rgba(255, 255, 255, 0.15);
}

.stat-number {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  background: linear-gradient(135deg, var(--off-white), var(--secondary-light));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.stat-label {
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Call to Action Section */
.cta-section {
  background: linear-gradient(120deg, var(--primary-light), var(--primary-dark));
  color: var(--white);
  padding: 5rem 2rem;
  text-align: center;
}

.cta-section h2 {
  color: var(--white);
  margin-bottom: 1.5rem;
}

.cta-section h2::after {
  display: none;
}

.cta-section p {
  max-width: 800px;
  margin: 0 auto 2.5rem;
  font-size: 1.2rem;
  color: var(--white);
  opacity: 0.9;
}

.cta-button {
  display: inline-block;
  background-color: var(--secondary-color);
  color: var(--white);
  padding: 1rem 2.5rem;
  border-radius: 50px;
  font-weight: 600;
  font-size: 1.1rem;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.cta-button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--secondary-light);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s ease;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.cta-button:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries */
@media (max-width: 1024px) {
  h1 {
    font-size: 3.25rem;
  }
  
  h2 {
    font-size: 2.25rem;
  }
  
  .initiatives-container {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.75rem;
  }
  
  h2 {
    font-size: 2rem;
  }
  
  .header-subtitle {
    font-size: 1.3rem;
  }
  
  .page-header {
    padding: 7rem 1.5rem;
  }
  
  .initiatives-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .initiative-image {
    height: 200px;
  }
  
  .education-section,
  .impact-section {
    padding: 4rem 1.5rem;
  }
  
  .stat-number {
    font-size: 3rem;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 2.25rem;
  }
  
  h2 {
    font-size: 1.75rem;
  }
  
  h3 {
    font-size: 1.35rem;
  }
  
  .header-subtitle {
    font-size: 1.1rem;
  }
  
  .page-header {
    padding: 6rem 1rem;
  }
  
  .education-intro p {
    font-size: 1.1rem;
  }
  
  .initiative-content {
    padding: 1.5rem;
  }
  
  .stats-container {
    grid-template-columns: 1fr 1fr;
  }
  
  .stat-number {
    font-size: 2.5rem;
  }
  
  .cta-button {
    display: block;
    margin: 1rem auto !important;
    width: 80%;
    max-width: 300px;
  }
}

@media (max-width: 400px) {
  .stats-container {
    grid-template-columns: 1fr;
  }
}
