/* gallery.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Base styles */
:root {
  /* Primary color palette - Warm Teal/Sage */
  --primary-color: #3a8f85;
  --primary-dark: #2c7269;
  --primary-light: #8cc5bf;
  --secondary-color: #d68c45;
  --secondary-dark: #b87339;
  --secondary-light: #e9b384;
  /* Neutral colors - Warmer and more organic */
  --dark: #2d3a3a;
  --dark-gray: #4d5c5c;
  --medium-gray: #7e8c8c;
  --light-gray: #d2d8d8;
  --off-white: #f8f7f5;
  --white: #ffffff;
  /* Accent colors - More earthy and harmonious */
  --success: #739e73;
  --warning: #e6b86a;
  --error: #c17b7b;
  --info: #6a91ab;
  
  /* Additional UI variables */
  --border-radius: 12px;
  --box-shadow: 0 8px 30px rgba(45, 58, 58, 0.12);
  --transition: all 0.3s ease;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--off-white);
  color: var(--dark);
  line-height: 1.6;
  overflow-x: hidden;
}

/* Navigation styles */
.nav {
  background-color: var(--white);
  box-shadow: 0 2px 15px rgba(45, 58, 58, 0.08);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.nav-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0.75rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  display: block;
}

.logo {
  height: 50px;
  width: auto;
  transition: var(--transition);
}

.logo:hover {
  transform: scale(1.05);
}

.nav-menu {
  display: flex;
  list-style: none;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  color: var(--dark-gray);
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  border-radius: var(--border-radius);
  transition: var(--transition);
  position: relative;
}

.nav-link:hover {
  color: var(--primary-color);
}

.nav-link.active {
  color: var(--primary-color);
  font-weight: 600;
}

.nav-link.active::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--primary-color);
  border-radius: 3px;
}

.btn-donate {
  background-color: var(--secondary-color);
  color: var(--white) !important;
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius);
  font-weight: 600;
  transition: var(--transition);
}

.btn-donate:hover {
  background-color: var(--secondary-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(214, 140, 69, 0.25);
}

.nav-dropdown {
  position: relative;
}

.nav-dropdown .nav-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--white);
  border-radius: var(--border-radius);
  padding: 1rem 0;
  min-width: 220px;
  box-shadow: var(--box-shadow);
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: var(--transition);
  z-index: 100;
}

.nav-dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-menu li {
  list-style: none;
}

.dropdown-menu a {
  display: block;
  color: var(--dark-gray);
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  transition: var(--transition);
}

.dropdown-menu a:hover {
  background-color: var(--light-gray);
  color: var(--primary-dark);
}

/* Main gallery styles */
.gallery-main {
  padding: 2rem 0 4rem;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 2rem;
}

.gallery-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--dark);
  text-align: center;
  margin-bottom: 2.5rem;
  position: relative;
  padding-bottom: 1rem;
}

.gallery-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  border-radius: 2px;
}

/* Filter bar */
.filter-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.75rem;
  margin-bottom: 3rem;
}

.filter-btn {
  background-color: var(--white);
  color: var(--dark-gray);
  border: 1px solid var(--light-gray);
  padding: 0.75rem 1.25rem;
  border-radius: var(--border-radius);
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition);
}

.filter-btn:hover {
  background-color: var(--light-gray);
  color: var(--dark);
  transform: translateY(-2px);
}

.filter-btn.active {
  background-color: var(--primary-color);
  color: var(--white);
  border-color: var(--primary-color);
  box-shadow: 0 4px 15px rgba(58, 143, 133, 0.25);
}

/* Gallery grid */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
}

.gallery-item {
  background-color: var(--white);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  cursor: pointer;
  position: relative;
  aspect-ratio: 1 / 1;
  transition: var(--transition);
}

.gallery-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px rgba(45, 58, 58, 0.18);
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition);
}

.gallery-item:hover img {
  transform: scale(1.05);
}

.gallery-item-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.25rem;
  background: linear-gradient(to top, rgba(45, 58, 58, 0.9), transparent);
  color: var(--white);
  font-weight: 500;
  opacity: 0;
  transform: translateY(20px);
  transition: var(--transition);
  pointer-events: none;
}

.gallery-item:hover .gallery-item-caption {
  opacity: 1;
  transform: translateY(0);
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 3rem;
}

.pagination button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--white);
  color: var(--dark-gray);
  border: 1px solid var(--light-gray);
  padding: 0.75rem 1.25rem;
  border-radius: var(--border-radius);
  font-weight: 500;
  cursor: pointer;
  transition: var(--transition);
}

.pagination button:hover:not(:disabled) {
  background-color: var(--primary-light);
  color: var(--primary-dark);
  border-color: var(--primary-light);
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination span {
  font-weight: 500;
  color: var(--dark-gray);
}

/* Lightbox modal */
.lightbox-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(45, 58, 58, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  animation: fadeIn 0.3s ease;
}

.lightbox-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background-color: var(--white);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.3);
  animation: scaleIn 0.3s ease;
}

.lightbox-content img {
  display: block;
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.lightbox-content p {
  padding: 1.5rem;
  text-align: center;
  font-weight: 500;
  color: var(--dark);
  border-top: 1px solid var(--light-gray);
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: var(--white);
  color: var(--dark);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(45, 58, 58, 0.15);
  transition: var(--transition);
  z-index: 10;
}

.close-btn:hover {
  background-color: var(--error);
  color: var(--white);
  transform: rotate(90deg);
}

/* Footer styles */
.footer {
  background-color: var(--dark);
  color: var(--white);
  padding: 4rem 0 0;
}

.footer-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
}

.footer-logo img {
  height: 60px;
  margin-bottom: 1rem;
}

.footer-tagline {
  color: var(--light-gray);
  font-size: 1rem;
  max-width: 300px;
}

.footer-heading {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0.75rem;
}

.footer-heading::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 3px;
  background-color: var(--secondary-color);
  border-radius: 2px;
}

.footer-links {
  list-style: none;
}

.footer-link {
  display: block;
  color: var(--light-gray);
  text-decoration: none;
  margin-bottom: 0.75rem;
  transition: var(--transition);
}

.footer-link:hover {
  color: var(--secondary-light);
  transform: translateX(5px);
}

.contact-info p {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
  color: var(--light-gray);
}

.contact-info a {
  color: var(--light-gray);
  text-decoration: none;
  transition: var(--transition);
}

.contact-info a:hover {
  color: var(--secondary-light);
}

.footer-bottom {
  text-align: center;
  padding: 2rem 0;
  margin-top: 3rem;
  border-top: 1px solid var(--dark-gray);
  color: var(--medium-gray);
  font-size: 0.9rem;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.8s ease forwards;
}

.scale-in {
  opacity: 0;
  animation: scaleIn 0.6s ease forwards;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .nav-container {
    padding: 0.75rem 1.5rem;
  }
  
  .nav-menu {
    gap: 1.25rem;
  }
  
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}

 @media (max-width: 768px) {
  .nav-menu {
    display: none; /* Mobile menu toggle would be implemented with JS */
  }
  
  .gallery-title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
  }

  .filter-bar {
    gap: 0.5rem;
    margin-bottom: 2rem;
  }

  .filter-btn {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }

  .footer-container {
    grid-template-columns: 1fr;
    text-align: center;
    padding: 0 1.5rem;
  }

  .footer-heading::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .footer-logo img {
    margin: 0 auto 1rem;
  }

  .footer-tagline {
    margin: 0 auto;
  }

  .contact-info p {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .nav-container {
    padding: 0.5rem 1rem;
  }

  .logo {
    height: 40px;
  }

  .gallery-title {
    font-size: 1.75rem;
  }

  .gallery-grid {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  .gallery-item-caption {
    padding: 0.75rem;
    font-size: 0.9rem;
  }

  .pagination {
    gap: 0.75rem;
  }

  .pagination button {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }

  .lightbox-content p {
    padding: 1rem;
    font-size: 0.9rem;
  }

  .close-btn {
    width: 35px;
    height: 35px;
    top: 10px;
    right: 10px;
  }

  .footer {
    padding: 2rem 0 0;
  }

  .footer-container {
    padding: 0 1rem;
    gap: 2rem;
  }

  .footer-heading {
    font-size: 1.1rem;
  }

  .footer-bottom {
    padding: 1.5rem 0;
    font-size: 0.85rem;
  }
}

/* Accessibility enhancements */
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

/* High contrast mode */
@media (prefers-contrast: high) {
  :root {
    --primary-color: #006666;
    --primary-dark: #004d4d;
    --primary-light: #b3ffff;
    --secondary-color: #cc6600;
    --secondary-dark: #994d00;
    --secondary-light: #ffcc99;
    --dark: #000000;
    --dark-gray: #333333;
    --medium-gray: #666666;
    --light-gray: #cccccc;
    --off-white: #ffffff;
    --white: #ffffff;
  }

  .nav-link:hover,
  .nav-link.active {
    text-decoration: underline;
  }

  .btn-donate {
    border: 2px solid var(--dark);
  }

  .filter-btn {
    border-width: 2px;
  }

  .gallery-item-caption {
    background: rgba(0, 0, 0, 0.8);
  }
}