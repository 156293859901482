/* community.css - Super modern styling for Community page */

/* Base styles */
.community-page {
    font-family: 'Outfit', 'Poppins', sans-serif;
    color: var(--dark);
    background-color: var(--off-white);
    line-height: 1.6;
    overflow-x: hidden;
    width: 100%;
    max-width: 100vw;
  }
  
  /* Header styles - Modern gradient with subtle pattern */
  .community-header {
    background: linear-gradient(120deg, var(--primary-dark), var(--primary-color));
    background-image: 
      radial-gradient(circle at 10% 20%, rgba(255, 255, 255, 0.07) 0%, transparent 20%),
      radial-gradient(circle at 90% 80%, rgba(255, 255, 255, 0.07) 0%, transparent 20%),
      linear-gradient(120deg, var(--primary-dark), var(--primary-color));
    color: var(--white);
    padding: 8rem 2rem;
    text-align: center;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  }
  
  .community-header::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.3), transparent);
    z-index: 1;
    pointer-events: none;
  }
  
  .community-header h1 {
    font-size: clamp(2.5rem, 5vw, 3.5rem);
    font-weight: 800;
    margin-bottom: 1.5rem;
    letter-spacing: 0.5px;
    position: relative;
    z-index: 2;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .community-header p {
    font-size: clamp(1.1rem, 2vw, 1.4rem);
    max-width: 800px;
    margin: 0 auto;
    font-weight: 300;
    line-height: 1.7;
    position: relative;
    z-index: 2;
  }
  
  /* Impact Statistics Section */
  .impact-section {
    padding: 6rem 2rem;
    background-color: var(--white);
    text-align: center;
    max-width: 100%;
  }
  
  .impact-section h2 {
    color: var(--primary-dark);
    font-size: clamp(1.8rem, 3vw, 2.5rem);
    margin-bottom: 3rem;
    font-weight: 700;
    position: relative;
    display: inline-block;
  }
  
  .impact-section h2::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
    border-radius: 2px;
  }
  
  /* Impact Cards - For the ImpactCards component */
  .impact-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .impact-card {
    background: var(--white);
    border-radius: 16px;
    padding: 2rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .impact-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
  }
  
  .impact-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
  }
  
  .impact-card .number {
    font-size: clamp(2.5rem, 5vw, 3.5rem);
    font-weight: 800;
    color: var(--primary-color);
    line-height: 1;
    margin-bottom: 1rem;
  }
  
  .impact-card .label {
    font-size: 1rem;
    font-weight: 500;
    color: var(--dark-gray);
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  /* Community Initiatives Section */
  .community-initiatives {
    padding: 6rem 2rem;
    background-color: var(--off-white);
    position: relative;
    overflow: hidden;
  }
  
  .community-initiatives::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, var(--primary-light) 0%, transparent 70%);
    opacity: 0.2;
    z-index: 0;
  }
  
  .community-initiatives h2 {
    color: var(--dark);
    font-size: clamp(1.8rem, 3vw, 2.5rem);
    margin-bottom: 3rem;
    font-weight: 700;
    position: relative;
    z-index: 1;
  }
  
  .community-initiatives h2::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 80px;
    height: 4px;
    background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
    border-radius: 2px;
  }
  
  .initiatives-list {
    max-width: 1200px;
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }
  
  .initiative-item {
    margin-bottom: 1.5rem;
    border-radius: 16px;
    overflow: hidden;
    background-color: var(--white);
    box-shadow: 0 10px 30px rgba(45, 58, 58, 0.07);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .initiative-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(45, 58, 58, 0.1);
  }
  
  .initiative-header {
    padding: 1.5rem 2rem;
    background-color: var(--white);
    color: var(--dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
  }
  
  .initiative-header::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: linear-gradient(to bottom, var(--primary-color), var(--primary-light));
    opacity: 0.7;
    transition: width 0.3s ease;
  }
  
  .initiative-header.active::before {
    width: 100%;
    opacity: 1;
  }
  
  .initiative-header.active {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  .initiative-header h3 {
    margin: 0;
    font-weight: 600;
    font-size: clamp(1.1rem, 2vw, 1.3rem);
    position: relative;
    z-index: 1;
  }
  
  .toggle-icon {
    transition: transform 0.3s ease;
    font-size: 0.8rem;
    color: var(--primary-color);
    position: relative;
    z-index: 1;
  }
  
  .initiative-header.active .toggle-icon {
    transform: rotate(180deg);
    color: var(--white);
  }
  
  .initiative-details {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    padding: 0 2rem;
    background-color: var(--white);
  }
  
  .initiative-details.active {
    max-height: 500px;
    padding: 1.5rem 2rem 2rem;
    border-top: 1px solid var(--light-gray);
  }
  
  .initiative-details p {
    margin-bottom: 1rem;
    line-height: 1.8;
    color: var(--dark-gray);
  }
  
  .initiative-details p:last-child {
    margin-bottom: 0;
  }
  
  /* Success Stories Section */
  .success-stories {
    padding: 6rem 2rem;
    background-color: var(--dark);
    color: var(--white);
    position: relative;
    overflow: hidden;
  }
  
  .success-stories::before {
    content: '';
    position: absolute;
    top: -50px;
    left: -50px;
    width: 200px;
    height: 200px;
    background: radial-gradient(circle, var(--secondary-light) 0%, transparent 70%);
    opacity: 0.1;
  }
  
  .success-stories h2 {
    text-align: center;
    font-size: clamp(1.8rem, 3vw, 2.5rem);
    margin-bottom: 3rem;
    font-weight: 700;
    color: var(--white);
    position: relative;
  }
  
  .success-stories h2::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: linear-gradient(90deg, var(--primary-light), var(--secondary-color));
    border-radius: 2px;
  }
  
  .stories-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .story-card {
    background: linear-gradient(135deg, var(--dark-gray), var(--dark));
    border-radius: 16px;
    padding: 2.5rem;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  .story-card::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100px;
    background: radial-gradient(circle, var(--secondary-color) 0%, transparent 70%);
    opacity: 0.1;
    z-index: 0;
  }
  
  .story-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  }
  
  .story-card h3 {
    color: var(--secondary-light);
    margin-bottom: 1.5rem;
    font-size: clamp(1.2rem, 2vw, 1.5rem);
    font-weight: 700;
    position: relative;
  }
  
  .story-card h3::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: var(--secondary-color);
    border-radius: 2px;
  }
  
  .story-quote {
    font-size: clamp(1.1rem, 2vw, 1.3rem);
    font-style: italic;
    color: var(--light-gray);
    margin-bottom: 1.5rem;
    line-height: 1.6;
    position: relative;
    padding-left: 1.5rem;
  }
  
  .story-quote::before {
    content: '"';
    position: absolute;
    left: 0;
    top: -10px;
    font-size: 2.5rem;
    color: var(--secondary-color);
    opacity: 0.5;
  }
  
  .story-text {
    color: var(--light-gray);
    margin-bottom: 2rem;
    line-height: 1.8;
    flex-grow: 1;
  }
  
  .story-card .image-placeholder {
    width: 100%;
    height: 200px;
    border-radius: 12px;
    overflow: hidden;
    margin-top: auto;
    position: relative;
  }
  
  .story-card .image-placeholder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .story-card:hover .image-placeholder img {
    transform: scale(1.05);
  }
  
  /* Call to Action Section */
  .cta-section {
    padding: 8rem 2rem;
    background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
    color: var(--white);
    text-align: center;
    position: relative;
    overflow: hidden;
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
    margin-top: -5rem;
  }
  
  .cta-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: 
      radial-gradient(circle at 20% 30%, rgba(255, 255, 255, 0.1) 0%, transparent 30%),
      radial-gradient(circle at 80% 70%, rgba(255, 255, 255, 0.1) 0%, transparent 30%);
    z-index: 1;
  }
  
  .cta-container {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  
  .cta-section h2 {
    font-size: clamp(2rem, 4vw, 3rem);
    font-weight: 800;
    margin-bottom: 1.5rem;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .cta-section p {
    font-size: clamp(1.1rem, 2vw, 1.3rem);
    margin-bottom: 3rem;
    line-height: 1.8;
  }
  
  .cta-button {
    display:  inline-block;
    padding: 1rem 2.5rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--primary-dark);
    background-color: var(--white);
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .cta-button:hover {
    background-color: var(--secondary-light);
    transform: translateY(-3px);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .community-header {
      padding: 6rem 1.5rem;
      clip-path: none;
    }
  
    .impact-section, 
    .community-initiatives, 
    .success-stories, 
    .cta-section {
      padding: 4rem 1.5rem;
    }
  
    .impact-cards-container,
    .stories-container {
      grid-template-columns: 1fr;
    }
  
    .initiative-header {
      padding: 1rem 1.5rem;
    }
  
    .cta-button {
      font-size: 1rem;
      padding: 0.8rem 2rem;
    }
  }
    
    
    