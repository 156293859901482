/* src/styles/index.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Playfair+Display:wght@400;700&display=swap');

:root {
  /* Primary color palette - Warm Teal/Sage */
  --primary-color: #3a8f85;
  --primary-dark: #2c7269;
  --primary-light: #8cc5bf;
  --secondary-color: #d68c45;
  --secondary-dark: #b87339;
  --secondary-light: #e9b384;

  /* Neutral colors - Warmer and more organic */
  --dark: #2d3a3a;
  --dark-gray: #4d5c5c;
  --medium-gray: #7e8c8c;
  --light-gray: #d2d8d8;
  --off-white: #f8f7f5;
  --white: #ffffff;

  /* Accent colors - More earthy and harmonious */
  --success: #739e73;
  --warning: #e6b86a;
  --error: #c17b7b;
  --info: #6a91ab;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: var(--off-white);
  color: var(--dark);
  line-height: 1.6;
  overflow-x: hidden;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding: 2rem;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
  color: var(--primary-dark);
}

h1 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 2.25rem;
  margin-bottom: 1.25rem;
}

p {
  font-size: 1.125rem;
  color: var(--dark-gray);
}

/* Buttons */
button, .btn {
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

button:hover, .btn:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

button.secondary, .btn.secondary {
  background-color: var(--secondary-color);
}

button.secondary:hover, .btn.secondary:hover {
  background-color: var(--secondary-dark);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .main-content {
    padding: 1.5rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .main-content {
    padding: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  p {
    font-size: 1rem;
  }

  button, .btn {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  }
}