/* Footer.css - Modern, dark, classy styling for Touched Hearts footer */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

.footer {
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: #e9e9e9;
  font-family: 'Montserrat', sans-serif;
  padding: 4rem 2rem 2rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.1);
}

.footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, transparent, #4a80f0, transparent);
}

.footer-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  max-width: 1400px;
  margin: 0 auto;
}

.footer-column {
  display: flex;
  flex-direction: column;
}

.footer-logo {
  display: inline-block;
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease;
}

.footer-logo:hover {
  transform: translateY(-3px);
}

.logo {
  max-width: 130px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.footer-tagline {
  color: #b8c1ec;
  font-size: 0.95rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
  letter-spacing: 0.3px;
  line-height: 1.5;
}

.social-links {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background-color: rgba(255, 255, 255, 0.08);
  color: #e9e9e9;
  border-radius: 50%;
  transition: all 0.3s ease;
  text-decoration: none;
}

.social-link:hover {
  background-color: #4a80f0;
  color: #fff;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(74, 128, 240, 0.3);
}

.footer-heading {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0.75rem;
}

.footer-heading::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background-color: #4a80f0;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-link {
  color: #b8c1ec;
  text-decoration: none;
  font-size: 0.95rem;
  display: block;
  padding: 0.5rem 0;
  transition: all 0.3s ease;
  position: relative;
}

.footer-link::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #4a80f0;
  transition: width 0.3s ease;
}

.footer-link:hover {
  color: #fff;
  transform: translateX(5px);
}

.footer-link:hover::before {
  width: 20px;
}

.contact-info {
  font-style: normal;
}

.contact-info p {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
  font-size: 0.95rem;
  color: #b8c1ec;
}

.contact-info a {
  color: #b8c1ec;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-info a:hover {
  color: #4a80f0;
}

.footer-bottom {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  gap: 1rem;
}

.copyright {
  color: #9ba3c2;
  font-size: 0.85rem;
}

.legal-links {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.legal-link {
  color: #9ba3c2;
  text-decoration: none;
  font-size: 0.85rem;
  transition: color 0.3s ease;
}

.legal-link:hover {
  color: #4a80f0;
}

/* Media Queries for better responsiveness */
@media (max-width: 1024px) {
  .footer {
    padding: 3rem 1.5rem 1.5rem;
  }
  
  .footer-container {
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  .footer-bottom {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .legal-links {
    margin-top: 0.5rem;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 2.5rem 1rem 1rem;
  }
  
  .footer-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .legal-links {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .footer-column:not(:last-child) {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
}

/* Animation for subtle hover effects */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.footer-link:active {
  animation: pulse 0.3s ease;
}