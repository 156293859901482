:root {
  /* Primary color palette - Warm Teal/Sage */
  --primary-color: #3a8f85;
  --primary-dark: #2c7269;
  --primary-light: #8cc5bf;
  --secondary-color: #d68c45;
  --secondary-dark: #b87339;
  --secondary-light: #e9b384;

  /* Neutral colors - Warmer and more organic */
  --dark: #2d3a3a;
  --dark-gray: #4d5c5c;
  --medium-gray: #7e8c8c;
  --light-gray: #d2d8d8;
  --off-white: #f8f7f5;
  --white: #ffffff;

  /* Accent colors - More earthy and harmonious */
  --success: #739e73;
  --warning: #e6b86a;
  --error: #c17b7b;
  --info: #6a91ab;
}

/* Base Styles */
.main-content {
  padding-top: 80px;
  background: var(--off-white);
  overflow-x: hidden;
  width: 100%;
  font-family: 'Inter', sans-serif;
  position: relative; /* Ensure proper stacking context */
}

/* General Section Styles */
.section-title {
  font-size: clamp(2.25rem, 5vw, 3.5rem);
  font-weight: 800;
  margin-bottom: 2.5rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: var(--dark); /* Fallback to solid color to avoid rendering issues */
  /* Removed gradient text effect to test if it’s the cause */
}

.section-title::after {
  content: '';
  width: 80px;
  height: 5px;
  background: var(--secondary-color);
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  transition: width 0.3s ease;
}

.section-title:hover::after {
  width: 100px;
}

.section-text {
  font-size: clamp(1.1rem, 2.5vw, 1.3rem);
  color: var(--medium-gray);
  line-height: 1.9;
  max-width: 900px;
  margin: 0 auto 2.5rem;
  text-align: center;
}

/* Mission Section */
.mission {
  padding: 6rem 2rem;
  background: var(--white); /* Simplified background */
}

.mission-container {
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}

.mission-content {
  padding: 2.5rem;
}

.mission-video {
  position: relative;
  aspect-ratio: 16/9;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(45, 58, 58, 0.1); /* Reduced shadow size */
}

.mission-video:hover {
  box-shadow: 0 12px 30px rgba(45, 58, 58, 0.15);
}

.mission-video-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;
}

.mission-video:hover .mission-video-element {
  transform: scale(1.05); /* Reduced scale to minimize rendering issues */
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--secondary-color);
  border: none;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: var(--white);
  font-size: 1.8rem;
  cursor: pointer;
  transition: all 0.4s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.play-button:hover {
  background: var(--secondary-dark);
  transform: translate(-50%, -50%) scale(1.1); /* Slightly reduced scale */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

/* Programs Section */
.programs {
  padding: 6rem 2rem;
  background: var(--light-gray);
}

.programs-grid {
  max-width: 1280px;
  margin: 0 auto 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
}

.program-card {
  background: var(--white);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(45, 58, 58, 0.1);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.program-card:hover {
  transform: translateY(-8px); /* Reduced lift */
  box-shadow: 0 12px 25px rgba(45, 58, 58, 0.15);
}

.program-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
  transition: transform 0.4s ease;
}

.program-card:hover .program-image {
  transform: scale(1.05);
}

.program-content {
  padding: 2rem;
}

.program-title {
  font-size: 1.6rem;
  color: var(--dark);
  margin-bottom: 1rem;
  font-weight: 700;
}

.program-text {
  color: var(--medium-gray);
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 1.5rem;
}

.program-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.program-link:hover {
  color: var(--primary-dark);
  transform: translateX(5px);
}

.programs-cta {
  text-align: center;
}

/* Testimonial Section */
.testimonial-section {
  padding: 6rem 2rem;
  position: relative;
  color: var(--white);
}

.testimonial-overlay {
  background: rgba(45, 58, 58, 0.65);
  border-radius: 20px;
  padding: 3rem;
  max-width: 1280px;
  margin: 0 auto;
}

/* News Section */
.news-updates {
  padding: 6rem 2rem;
  background: var(--white);
}

.news-grid {
  max-width: 1280px;
  margin: 0 auto 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2.5rem;
}

.news-card {
  background: var(--off-white);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.4s ease;
}

.news-card:hover {
  box-shadow: 0 10px 25px rgba(45, 58, 58, 0.15);
  transform: translateY(-8px);
}

.news-image {
  width: 100%;
  height: 240px;
  object-fit: cover;
  transition: transform 0.4s ease;
}

.news-card:hover .news-image {
  transform: scale(1.05);
}

.news-content {
  padding: 2rem;
}

.news-date {
  display: block;
  color: var(--primary-light);
  font-size: 0.95rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.news-title {
  font-size: 1.4rem;
  color: var(--dark);
  margin-bottom: 1rem;
  font-weight: 700;
}

.news-excerpt {
  color: var(--medium-gray);
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 1.5rem;
}

.text-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.text-link:hover {
  color: var(--primary-dark);
  transform: translateX(5px);
}

.news-cta {
  text-align: center;
}

/* CTA Section */
.cta-section {
  padding: 6rem 2rem;
  background: var(--primary-dark); /* Simplified background */
  color: var(--white);
}

.cta-container {
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
}

.cta-title {
  font-size: clamp(2.25rem, 5vw, 3.5rem);
  margin-bottom: 2rem;
  color: var(--white);
  font-weight: 800;
  letter-spacing: -0.02em;
}

.cta-title::after {
  background: var(--secondary-light);
}

.cta-text {
  font-size: 1.3rem;
  color: var(--off-white);
  margin-bottom: 3rem;
  line-height: 1.8;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.cta-button {
  padding: 1rem 2.5rem;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all 0.4s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.cta-button.primary {
  background: var(--secondary-color);
  color: var(--white);
}

.cta-button.primary:hover {
  background: var(--secondary-dark);
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
}

.cta-button.secondary {
  background: transparent;
  color: var(--white);
  border: 2px solid var(--secondary-light);
}

.cta-button.secondary:hover {
  background: var(--secondary-light);
  color: var(--dark);
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .mission-container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .mission-video {
    max-width: 700px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .main-content {
    padding-top: 60px;
  }

  .mission,
  .programs,
  .news-updates,
  .cta-section,
  .testimonial-section {
    padding: 4rem 1.5rem;
  }

  .program-card,
  .news-card {
    max-width: 450px;
    margin: 0 auto;
  }

  .cta-buttons {
    flex-direction: column;
    gap: 1.5rem;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 2rem;
  }

  .section-text {
    font-size: 1rem;
  }

  .program-image,
  .news-image {
    height: 200px;
  }

  .play-button {
    width: 60px;
    height: 60px;
    font-size: 1.5rem;
  }

  .cta-title {
    font-size: 1.8rem;
  }

  .cta-text {
    font-size: 1.1rem;
  }
}