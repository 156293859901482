:root {
  /* Primary color palette - Warm Teal/Sage */
  --primary-color: #3a8f85;
  --primary-dark: #2c7269;
  --primary-light: #8cc5bf;
  --secondary-color: #d68c45;
  --secondary-dark: #b87339;
  --secondary-light: #e9b384;
  --dark: #2d3a3a;
  --dark-gray: #4d5c5c;
  --medium-gray: #7e8c8c;
  --light-gray: #d2d8d8;
  --off-white: #f8f7f5;
  --white: #ffffff;
  --success: #739e73;
  --warning: #e6b86a;
  --error: #c17b7b;
  --info: #6a91ab;
}

.fundraise-container {
  min-height: 100vh;
  background: linear-gradient(135deg, var(--off-white) 0%, var(--light-gray) 100%);
  font-family: 'Inter', sans-serif;
  color: var(--dark);
  padding: 0 2rem;
}

/* Page Header */
.page-header {
  padding: 4rem 2rem;
  text-align: center;
  background: linear-gradient(to bottom, rgba(58, 143, 133, 0.1), rgba(255, 255, 255, 0));
}

.page-header h1 {
  font-size: clamp(2.5rem, 5vw, 4rem);
  color: var(--primary-dark);
  margin-bottom: 1rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  animation: fadeInUp 0.8s ease-out;
}

.page-header p {
  font-size: clamp(1rem, 2.5vw, 1.25rem);
  color: var(--medium-gray);
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
  animation: fadeInUp 0.8s ease-out 0.2s backwards;
}

/* Main Content Container */
.fundraise-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 2rem;
  padding-bottom: 4rem;
}

/* Fundraise Sections */
.fundraise-section {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--light-gray);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.fundraise-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(58, 143, 133, 0.15);
}

.fundraise-section h2 {
  font-size: 1.75rem;
  color: var(--primary-dark);
  margin-bottom: 1rem;
  font-weight: 600;
}

.fundraise-section p {
  color: var(--dark-gray);
  line-height: 1.6;
  font-size: 1rem;
  margin-bottom: 1rem;
}

/* Intro Section */
.intro {
  text-align: center;
}

/* Campaign Grid */
.campaign-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.campaign-card {
  background: var(--white);
  border-radius: 0.75rem;
  padding: 1.5rem;
  border: 1px solid var(--light-gray);
  transition: all 0.3s ease;
}

.campaign-card:hover {
  border-color: var(--primary-light);
  box-shadow: 0 6px 12px rgba(58, 143, 133, 0.1);
}

.campaign-card h3 {
  font-size: 1.25rem;
  color: var(--primary-dark);
  margin-bottom: 0.75rem;
}

.campaign-card p {
  margin: 0;
}

/* Form Section */
.form-section {
  max-width: 700px;
  margin: 0 auto;
}

.fundraiser-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-size: 1rem;
  color: var(--primary-dark);
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  color: var(--dark);
  background: var(--white);
  border: 1px solid var(--light-gray);
  border-radius: 0.5rem;
  outline: none;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(58, 143, 133, 0.2);
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

/* Buttons */
.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background: var(--success);
  color: var(--white);
  text-decoration: none;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
  border: 1px solid var(--success);
  cursor: pointer;
  text-align: center;
}

.btn:hover {
  background: #5f875f; /* Slightly darker success */
  border-color: #5f875f;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(115, 158, 115, 0.2);
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .fundraise-container {
    padding: 0 1.5rem;
    gap: 1.5rem;
  }

  .page-header {
    padding: 3rem 1.5rem;
  }

  .fundraise-section {
    padding: 1.5rem;
  }

  .campaign-grid {
    grid-template-columns: 1fr;
  }

  .page-header h1 {
    font-size: 2.5rem;
  }

  .fundraise-section h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .page-header h1 {
    font-size: 2rem;
  }

  .page-header p {
    font-size: 0.9rem;
  }

  .fundraise-section {
    padding: 1rem;
  }

  .fundraise-section h2 {
    font-size: 1.25rem;
  }

  .btn {
    width: 100%;
  }
}