/* contact-form.css */
.newsletter {
  padding: 5rem 2rem;
  background: linear-gradient(135deg, var(--primary-dark), var(--primary-color));
  position: relative;
  overflow: hidden;
}

.newsletter-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
}

/* Title Styling */
.section-title {
  font-size: clamp(2rem, 4vw, 3rem);
  color: var(--off-white);
  font-weight: 700;
  margin-bottom: 1.5rem;
  position: relative;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.section-title::after {
  content: '';
  width: 60px;
  height: 4px;
  background: var(--secondary-light);
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
}

/* Text Styling */
.newsletter-text {
  font-size: clamp(1rem, 2vw, 1.25rem);
  color: var(--light-gray);
  line-height: 1.8;
  margin-bottom: 2.5rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Form Styling */
.newsletter-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

.form-group {
  display: flex;
  width: 100%;
  max-width: 500px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.form-group:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25);
}

.form-group input {
  flex: 1;
  padding: 1rem 1.5rem;
  border: none;
  background: transparent;
  color: var(--white);
  font-size: 1.1rem;
  outline: none;
}

.form-group input::placeholder {
  color: var(--light-gray);
  opacity: 0.8;
}

.form-group input:focus {
  background: rgba(255, 255, 255, 0.05);
}

/* Button Styling */
.cta-button.primary {
  padding: 1rem 2rem;
  background: var(--secondary-color);
  color: var(--white);
  border: none;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.cta-button.primary::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.4s ease, height 0.4s ease;
}

.cta-button.primary:hover {
  background: var(--secondary-dark);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(214, 140, 69, 0.4);
}

.cta-button.primary:hover::after {
  width: 200%;
  height: 200%;
}

/* Checkbox Styling */
.checkbox-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: var(--light-gray);
  font-size: 0.9rem;
  max-width: 500px;
}

.checkbox-container input {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid var(--secondary-light);
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkbox-container input:checked {
  background: var(--secondary-color);
  border-color: var(--secondary-dark);
}

.checkbox-container input:checked::after {
  content: '\2713';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  font-size: 1rem;
}

.checkbox-label a {
  color: var(--secondary-light);
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.checkbox-label a:hover {
  color: var(--secondary-dark);
}

/* Background Effect */
.newsletter::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, transparent 70%);
  animation: rotate 20s linear infinite;
  z-index: 1;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .newsletter {
    padding: 3rem 1rem;
  }

  .form-group {
    flex-direction: column;
    border-radius: 15px;
    max-width: 400px;
  }

  .form-group input {
    padding: 1.2rem;
    border-radius: 15px 15px 0 0;
  }

  .cta-button.primary {
    padding: 1rem;
    border-radius: 0 0 15px 15px;
  }

  .checkbox-container {
    text-align: left;
    padding: 0 1rem;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 1.8rem;
  }

  .newsletter-text {
    font-size: 1rem;
  }

  .form-group input {
    font-size: 1rem;
  }

  .cta-button.primary {
    font-size: 1rem;
  }
}
