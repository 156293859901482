/* src/styles/DonateWidget.css */
:root {
  /* Primary color palette - Warm Teal/Sage */
  --primary-color: #3a8f85;
  --primary-dark: #2c7269;
  --primary-light: #8cc5bf;
  --secondary-color: #d68c45;
  --secondary-dark: #b87339;
  --secondary-light: #e9b384;

  /* Neutral colors - Warmer and more organic */
  --dark: #2d3a3a;
  --dark-gray: #4d5c5c;
  --medium-gray: #7e8c8c;
  --light-gray: #d2d8d8;
  --off-white: #f8f7f5;
  --white: #ffffff;

  /* Accent colors - More earthy and harmonious */
  --success: #739e73;
  --warning: #e6b86a;
  --error: #c17b7b;
  --info: #6a91ab;
}

.donate-widget {
  padding: 40px 20px;
  background: linear-gradient(135deg, var(--off-white) 0%, var(--light-gray) 100%);
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(46, 58, 58, 0.1);
  max-width: 540px;
  margin: 30px auto;
  transition: transform 0.3s ease;
}

.donate-widget:hover {
  transform: translateY(-5px);
}

.donate-widget-container {
  text-align: center;
}

.donate-widget-container h2 {
  font-size: 28px;
  color: var(--dark);
  margin-bottom: 12px;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.donate-widget-container p {
  font-size: 16px;
  color: var(--medium-gray);
  margin-bottom: 24px;
  line-height: 1.5;
}

.amount-options {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 28px;
}

.amount-btn {
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  color: var(--dark-gray);
  background-color: var(--white);
  border: 2px solid var(--primary-light);
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.amount-btn:hover {
  background-color: var(--primary-light);
  color: var(--dark);
}

.amount-btn.active {
  background-color: var(--primary-color);
  color: var(--white);
  border-color: var(--primary-dark);
  box-shadow: 0 4px 12px rgba(58, 143, 133, 0.3);
}

.frequency-options {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 28px;
}

.frequency-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: var(--dark-gray);
  cursor: pointer;
}

.frequency-label input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}

.frequency-label input[type="radio"]:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-dark);
}

.frequency-label input[type="radio"]:checked::after {
  content: '';
  width: 10px;
  height: 10px;
  background-color: var(--white);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.frequency-label span {
  font-weight: 500;
}

.donate-btn {
  display: inline-block;
  padding: 14px 32px;
  background: linear-gradient(90deg, var(--secondary-color) 0%, var(--secondary-light) 100%);
  color: var(--white);
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  border-radius: 50px;
  transition: all 0.3s ease;
}

.donate-btn:hover {
  background: linear-gradient(90deg, var(--secondary-dark) 0%, var(--secondary-color) 100%);
  box-shadow: 0 6px 16px rgba(214, 140, 69, 0.3);
  transform: translateY(-2px);
}

.impact-summary {
  margin-top: 28px;
  padding: 20px;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(46, 58, 58, 0.05);
}

.impact-summary h3 {
  font-size: 20px;
  color: var(--primary-dark);
  margin-bottom: 12px;
  font-weight: 600;
}

.impact-summary p {
  font-size: 14px;
  color: var(--medium-gray);
  margin-bottom: 12px;
}

.impact-summary ul {
  list-style: none;
  padding: 0;
  font-size: 14px;
  color: var(--dark-gray);
  text-align: left;
}

.impact-summary li {
  margin-bottom: 8px;
  padding-left: 20px;
  position: relative;
}

.impact-summary li::before {
  content: '•';
  color: var(--secondary-color);
  font-size: 18px;
  position: absolute;
  left: 0;
}