:root {
  /* Vibrant Primary Palette - Bright Teal/Coral */
  --primary-color: #00c4b4; /* Vivid Teal */
  --primary-dark: #009688;  /* Deep Teal */
  --primary-light: #66d9d1; /* Light Teal */
  --secondary-color: #ff7043; /* Bright Coral */
  --secondary-dark: #e64a19; /* Deep Coral */
  --secondary-light: #ffab91; /* Soft Coral */

  /* Neutral Colors - Bolder and Warmer */
  --dark: #1a2e35; /* Rich Charcoal */
  --dark-gray: #455a64; /* Slate */
  --medium-gray: #78909c; /* Steel Blue */
  --light-gray: #eceff1; /* Pale Gray */
  --off-white: #fff8e1; /* Warm Off-White */
  --white: #ffffff;

  /* Accent Colors - More Energetic */
  --success: #4caf50; /* Vibrant Green */
  --warning: #ffca28; /* Bold Amber */
  --error: #ef5350; /* Striking Red */
  --info: #42a5f5; /* Lively Blue */

  /* Typography */
  --heading-font: 'Montserrat', sans-serif;
  --body-font: 'Open Sans', sans-serif;
}

/* Rest of the CSS remains largely the same, with updated color variables */
.testimonial-slider {
  position: relative;
  max-width: 960px;
  margin: 7rem auto;
  padding: 0 2.5rem;
  overflow: hidden;
  isolation: isolate;
  background: radial-gradient(circle at center, rgba(0, 196, 180, 0.1) 0%, rgba(255, 248, 225, 0) 70%);
}

.testimonial-slider h2 {
  font-family: var(--heading-font);
  font-size: clamp(2rem, 4.5vw, 3rem);
  font-weight: 800;
  text-align: center;
  margin-bottom: 4rem;
  color: var(--dark);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: linear-gradient(90deg, var(--primary-dark), var(--secondary-color));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradientShift 8s ease infinite;
}

.testimonial-slider h2::before {
  content: '';
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: radial-gradient(circle, var(--primary-light) 20%, transparent 70%);
  opacity: 0.25;
  top: -30px;
  left: -40px;
  z-index: -1;
  animation: pulseGlow 5s ease-in-out infinite;
}

.testimonial-slider h2::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, var(--secondary-dark), var(--primary-dark));
  border-radius: 12px;
  box-shadow: 0 0 12px rgba(0, 196, 180, 0.6);
}

.testimonial {
  position: relative;
  background: linear-gradient(145deg, var(--white) 0%, var(--off-white) 100%);
  padding: 4.5rem 3.5rem;
  border-radius: 20px;
  box-shadow: 
    0 20px 40px rgba(26, 46, 53, 0.1),
    0 8px 20px rgba(0, 0, 0, 0.06),
    inset 0 1px 1px rgba(255, 255, 255, 0.5);
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.testimonial:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 
    0 25px 50px rgba(26, 46, 53, 0.15),
    0 10px 25px rgba(0, 0, 0, 0.08);
}

.testimonial::before {
  content: '“';
  font-family: Georgia, serif;
  font-size: 14rem;
  opacity: 0.08;
  top: -3rem;
  left: 1.5rem;
  color: var(--primary-dark);
  transition: opacity 0.3s ease;
}

.testimonial:hover::before {
  opacity: 0.12;
}

.testimonial::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 100%;
  background: linear-gradient(180deg, var(--primary-dark), var(--secondary-light));
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 0 15px rgba(0, 196, 180, 0.4);
}

.testimonial p {
  font-family: var(--body-font);
  font-size: clamp(1.15rem, 2.2vw, 1.5rem);
  line-height: 1.7;
  color: var(--dark-gray);
  margin-bottom: 2rem;
  animation: fadeSlideIn 0.9s ease-out forwards;
}

.testimonial h4 {
  font-family: var(--heading-font);
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--secondary-dark);
  margin-top: 2rem;
  text-align: right;
  animation: fadeSlideIn 0.9s 0.3s ease-out forwards;
  opacity: 0;
}

/* Navigation Buttons */
.nav-button {
  position: absolute;
  bottom: -2rem;
  background: linear-gradient(135deg, var(--primary-dark), var(--secondary-color));
  color: var(--white);
  border: none;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  font-family: var(--heading-font);
  font-weight: 700;
  cursor: pointer;
  transition: all 0.4s ease;
  box-shadow: 
    0 12px 25px rgba(0, 150, 136, 0.25),
    0 8px 10px rgba(0, 150, 136, 0.15),
    inset 0 2px 3px rgba(255, 255, 255, 0.3);
}

.nav-button.prev {
  right: 8rem;
}

.nav-button.next {
  right: 2rem;
}

.nav-button:hover {
  transform: translateY(-5px) scale(1.08);
  box-shadow: 
    0 18px 35px rgba(0, 150, 136, 0.3),
    0 12px 15px rgba(0, 150, 136, 0.2);
}

.nav-button:active {
  transform: translateY(0) scale(0.95);
}

.nav-button::after {
  font-size: 1.75rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav-button.prev::after {
  content: '←';
}

.nav-button.next::after {
  content: '→';
}

/* Geometric Accents and Animations remain the same */
.testimonial-slider::before {
  content: '';
  position: absolute;
  width: 240px;
  height: 240px;
  border: 1px solid var(--light-gray);
  border-radius: 50%;
  top: -120px;
  right: -120px;
  z-index: -1;
  opacity: 0.6;
  background: radial-gradient(circle, rgba(0, 196, 180, 0.05) 0%, transparent 70%);
  animation: float 12s ease-in-out infinite;
}

.testimonial-slider::after {
  content: '';
  position: absolute;
  width: 180px;
  height: 180px;
  border: 1px dashed var(--primary-light);
  bottom: -90px;
  left: -90px;
  z-index: -1;
  opacity: 0.5;
  animation: rotateSlowly 25s linear infinite reverse;
}

@keyframes fadeSlideIn {
  from { opacity: 0; transform: translateY(30px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes rotateSlowly {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes pulseGlow {
  0% { transform: scale(1); opacity: 0.25; }
  50% { transform: scale(1.15); opacity: 0.4; }
  100% { transform: scale(1); opacity: 0.25; }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@media (max-width: 768px) {
  .testimonial-slider {
    padding: 0 2rem;
    margin: 5rem auto;
  }

  .testimonial {
    padding: 3.5rem 2.5rem;
  }

  .nav-button.prev {
    right: 6rem;
  }

  .nav-button.next {
    right: 1rem;
  }
}