/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100vw;
  height: 100%;
  overflow-x: hidden; /* Prevents unwanted horizontal scrolling */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #f8f9fa; /* Light background for consistency */
  color: #333; /* Default text color */
}

/* App Container */
.app-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures full height */
  margin: 0;
  padding: 0;
}

/* Main Content Area */
.main-content {
  flex: 1; /* Pushes content to take available space */
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
}