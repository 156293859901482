/* About Page Styles */

/* Global & Layout */
.main-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  color: var(--dark);
  background-color: var(--white);
  font-family: 'Inter', 'Segoe UI', system-ui, sans-serif;
}

/* About Section - Hero Area */
.about-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  margin-bottom: 5rem;
  align-items: center;
}

.about-text {
  order: 2;
}

.about-text h2 {
  color: var(--primary-color);
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  position: relative;
}

.about-text h2::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 4rem;
  height: 3px;
  background-color: var(--secondary-color);
  border-radius: 2px;
}

.about-text p {
  color: var(--dark-gray);
  font-size: 1.125rem;
  line-height: 1.7;
  margin-bottom: 2.5rem;
}

.about-image {
  order: 1;
  margin: 0;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(45, 58, 58, 0.12);
}

.about-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.8s ease;
  display: block;
}

.about-image:hover img {
  transform: scale(1.03);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Why We Exist Section */
.why-we-exist {
  margin-bottom: 5rem;
  text-align: center;
}

.why-we-exist h2 {
  color: var(--primary-color);
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2.5rem;
  position: relative;
  display: inline-block;
}

.why-we-exist h2::after {
  content: '';
  position: absolute;
  bottom: -0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: 5rem;
  height: 3px;
  background-color: var(--secondary-color);
  border-radius: 2px;
}

.exist-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.exist-item {
  background-color: var(--off-white);
  border-radius: 12px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 6px 12px rgba(45, 58, 58, 0.08);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.exist-item:hover {
  transform: translateY(-8px);
  box-shadow: 0 15px 25px rgba(45, 58, 58, 0.1);
}

.exist-item img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1.25rem;
}

.exist-item h3 {
  color: var(--primary-dark);
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.exist-item p {
  color: var(--dark-gray);
  font-size: 1rem;
  line-height: 1.6;
  flex-grow: 1;
}

/* Meet the Team Section */
.meet-team {
  text-align: center;
  background-color: var(--primary-light);
  background: linear-gradient(135deg, var(--primary-light) 0%, var(--primary-color) 100%);
  padding: 4rem 2rem;
  border-radius: 12px;
  margin-bottom: 3rem;
  color: var(--white);
}

.meet-team h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.meet-team p {
  font-size: 1.25rem;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto 2.5rem;
}

.btn {
  display: inline-block;
  padding: 0.875rem 2rem;
  font-size: 1.125rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.3s ease;
  text-align: center;
}

.cta-button.primary {
  background-color: var(--secondary-color);
  color: var(--white);
  box-shadow: 0 5px 15px rgba(214, 140, 69, 0.4);
}

.cta-button.primary:hover {
  background-color: var(--secondary-dark);
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(214, 140, 69, 0.5);
}

/* Responsive Styles */
@media screen and (min-width: 768px) {
  .main-content {
    padding: 3rem 2rem;
  }

  .about-content {
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }

  .about-text {
    order: 1;
  }

  .about-image {
    order: 2;
  }

  .exist-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .main-content {
    padding: 4rem 3rem;
  }

  .about-content {
    gap: 5rem;
  }

  .exist-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .about-text h2 {
    font-size: 2.5rem;
  }

  .about-text p {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .about-content {
    gap: 6rem;
  }
}

/* Animation for smoother page load */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.main-content > section {
  animation: fadeIn 0.8s ease-out forwards;
}

.about-content {
  animation-delay: 0.1s;
}

.why-we-exist {
  animation-delay: 0.3s;
}

.meet-team {
  animation-delay: 0.5s;
}